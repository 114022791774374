import { useState } from "react";
import { useGoogleLogin as useGoogleOAuth } from "@react-oauth/google";
import { useStoreActions } from "easy-peasy";
import { getRedirectURL } from "../helpers";

export const useGoogleLogin = ({
  onError,
  onSuccess: onSuccessGoogleLogin,
}: {
  onError: (_message?: string) => void;
  onSuccess: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const logInGoogle = useStoreActions(
    (actions) => (actions.auth as any).logInGoogle,
  );

  const handleLoginPress = useGoogleOAuth({
    onSuccess: async (response) => {
      await logInGoogle({
        token: response.access_token,
        request_uri: getRedirectURL(),
      });

      onSuccessGoogleLogin();
      setIsLoading(false);
    },
    onError: ({ error }) => {
      onError(error);
      setIsLoading(false);
    },
    onNonOAuthError: () => {
      setIsLoading(false);
    },
  });

  const onLoginPress = () => {
    setIsLoading(true);
    handleLoginPress();
  };

  return { onLoginPress, isLoading };
};
