import { v4 as uuid } from "uuid";
import { useStoreActions } from "easy-peasy";
import { useCallback, useEffect, useState } from "react";

import { getRedirectURL } from "../helpers";

// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
export const useAppleLogin = ({
  onError,
  onSuccess,
}: {
  onError: (_message?: string) => void;
  onSuccess: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const logInApple = useStoreActions(
    (actions) => (actions.auth as any).logInApple,
  );

  const loadSdk = useCallback(() => {
    const script = document.createElement("script");
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    script.type = "text/javascript";

    script.onload = () => {
      initialize();
    };

    const element = document.body.appendChild(script);
    element.id = appleSdkId;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initialize = useCallback(() => {
    const state = uuid();
    const nonce = uuid();

    localStorage.setItem(OAUTH_STATE_KEY, state);

    window.AppleID.auth.init({
      clientId: "com.kic.kicapp.client",
      scope: "name email",
      redirectURI: getRedirectURL(),
      state,
      nonce,
      usePopup: true,
    });
  }, []);

  useEffect(() => {
    loadSdk();
  }, []);

  const onLoginPress = useCallback(async () => {
    setIsLoading(true);

    try {
      const {
        authorization: { code, state },
      } = await window.AppleID.auth.signIn();

      const savedState = localStorage.getItem(OAUTH_STATE_KEY);

      if (savedState !== state) {
        return onError();
      }

      await logInApple({
        code,
        request_uri: getRedirectURL(),
      });

      onSuccess();
    } catch (error) {
      onError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { onLoginPress, isLoading };
};

declare global {
  interface Window {
    AppleID: {
      auth: {
        init: (_params: {
          clientId: string;
          scope: string;
          redirectURI: string;
          state: string;
          nonce: string;
          usePopup: true;
        }) => void;
        signIn: () => Response;
      };
    };
  }
}

type Response = {
  authorization: {
    code: string;
    id_token: string;
    state: string;
  };
  user: {
    email: string;
    name: {
      firstName: string;
      lastName: string;
    };
  };
};

const appleSdkId = "apple-jssdk";

const OAUTH_STATE_KEY = "OAUTH_STATE";
