import React from "react";
import Link from "next/link";

import { Box, Text } from "../../../ui";

export const TermsAndConditions = () => {
  return (
    <Box alignItems="center" py="md" my="xxs">
      <Text variant="bodySmall" color="contentContrastMid">
        By continuing you agree to
      </Text>
      <Link href={"/legal"}>
        <a>
          <Text variant="bodySmall" textDecoration="underline">
            Terms and Conditions & Privacy Policy
          </Text>
        </a>
      </Link>
    </Box>
  );
};
