import React, { useCallback, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Callout,
  Divider,
  LoadingIndicator,
  Text,
} from "../../../ui";
import { logEvent } from "../../../utils/monitoring";
import { logRoktAccountCreated } from "../../../utils/analytics/roktAnalytics";
import { useUser } from "../../../hooks";
import { useAppleLogin } from "../hooks/useAppleLogin";
import { useFacebookLogin } from "../hooks/useFacebookLogin";
import { TermsAndConditions } from "../../Onboarding/components/TermsAndConditions";
import { OnboardingImageCarousel } from "../../Onboarding/components/OnboardingImageCarousel";

import { _Input } from "../../../ui/TextInput/BaseTextInput";
import { useGoogleLogin } from "../hooks/useGoogleLogin";

type LoginViewProps = {
  onLogInPress: () => void;
  isLoading: boolean;
  error: string;
  email: string;
  setEmail: (_value: string) => void;
  password: string;
  setPassword: (_value: string) => void;
};

export const LoginView: React.FC<LoginViewProps> = ({
  onLogInPress,
  email,
  setEmail,
  password,
  setPassword,
  isLoading,
  error,
}) => {
  const router = useRouter();
  const user = useUser();
  const [errorMessage, setErrorMessage] = useState("");
  const { onLoginPress: onAppleLogInPress, isLoading: isLoadingApple } =
    useAppleLogin({
      onError: setErrorMessage,
      onSuccess: () => {
        logRoktAccountCreated({ email: user?.email });
        router.push("onboarding");
      },
    });

  const { onLoginPress: onFacebookLogInPress, isLoading: isLoadingFacebook } =
    useFacebookLogin({
      onError: setErrorMessage,
      onSuccess: () => {
        logRoktAccountCreated({ email: user?.email });
        router.push("onboarding");
      },
    });

  const { onLoginPress: onGoogleLogInPress, isLoading: isLoadingGoogle } =
    useGoogleLogin({
      onError: setErrorMessage,
      onSuccess: () => {
        logRoktAccountCreated({ email: user?.email });
        router.push("onboarding");
      },
    });

  const onStartFacebookAuth = useCallback(() => {
    logEvent({
      eventName: "WebSignupStarted",
      method: "facebook",
    });
    onFacebookLogInPress();
  }, [logEvent, onFacebookLogInPress]);

  const onStartAppleAuth = useCallback(() => {
    logEvent({
      eventName: "WebSignupStarted",
      method: "apple",
    });
    onAppleLogInPress();
  }, [logEvent, onAppleLogInPress]);

  const onStartGoogleAuth = useCallback(() => {
    logEvent({
      eventName: "WebSignupStarted",
      method: "google",
    });
    onGoogleLogInPress();
  }, [logEvent, onGoogleLogInPress]);

  const isSsoLoginLoading =
    isLoadingApple || isLoadingFacebook || isLoadingGoogle;

  return (
    <Box flex={1} flexDirection={["column", "row"]}>
      <Box flex={1}>
        <OnboardingImageCarousel />
      </Box>
      <Box flex={2} alignItems="center" px="sm">
        <Box justifyContent="space-between">
          <Box mt={["md", "xl"]} mb="lg">
            <Text variant="hero" textAlign="center">
              Welcome back!
            </Text>
            <Text mt="sm" mb="md" variant="bodySmall" textAlign="center">
              Login to your account
            </Text>

            {isSsoLoginLoading ? (
              <LoadingIndicator size="large" />
            ) : (
              <Box>
                <Box mb="sm">
                  <_Input
                    data-cy="email-input"
                    autoFocus
                    type="email"
                    placeholder="Email Address"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Box mt="sm">
                    <_Input
                      data-cy="password-input"
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Box>
                  {error && (
                    <Callout data-cy="error-msg" variant="error" mt="md">
                      <Text variant="bodySmall">{error}</Text>
                    </Callout>
                  )}
                  <Button
                    data-cy="login-with-email"
                    variant="buttonFilledBrand"
                    text="Login with email"
                    onClick={onLogInPress}
                    isLoading={isLoading}
                    mt={"md"}
                  />
                </Box>
                <Box alignItems="center">
                  <Link href="/request-reset">
                    <Text
                      variant="buttonSmall"
                      m="sm"
                      css={{ cursor: "pointer" }}
                      textDecoration="underline"
                    >
                      Forgot your password?
                    </Text>
                  </Link>
                </Box>

                <Box mb="sm" flexDirection="row" alignItems="center">
                  <Divider my="sm" />
                  <Box mx="sm">
                    <Text variant="labelSmall">or</Text>
                  </Box>
                  <Divider my="sm" />
                </Box>

                <Box gap="sm">
                  <Button
                    iconName="google"
                    variant="buttonFilledLight"
                    text="Continue with Google"
                    onClick={() => onStartGoogleAuth()}
                  />
                  <Button
                    iconName="apple"
                    variant="buttonFilledLight"
                    text="Continue with Apple"
                    onClick={() => onStartAppleAuth()}
                  />
                  <Button
                    iconName="facebook"
                    variant="buttonFilledLight"
                    text="Continue with Facebook"
                    onClick={() => onStartFacebookAuth()}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <TermsAndConditions />

          {!!errorMessage && (
            <Callout variant="error" mt="md">
              <Text variant="body">{errorMessage}</Text>
            </Callout>
          )}
        </Box>
      </Box>
    </Box>
  );
};
