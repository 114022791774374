import Cookies from "js-cookie";
import { logEvent } from "../monitoring";

export type ROKTEventProperties = {
  eventName: "ROKTPaymentCompleted" | "ROKTAccountCreated";
  email?: string;
  plan?: string;
  passbackconversiontrackingid: string;
};

const getRoktCookie = () => Cookies.get("RoktTransactionId");

export const logRoktAccountCreated = ({ email }: { email?: string }) => {
  const roktTransactionId = getRoktCookie();
  if (roktTransactionId) {
    logEvent({
      eventName: "ROKTAccountCreated",
      passbackconversiontrackingid: roktTransactionId,
      email,
    });
  }
};

export const logRoktPayment = ({
  plan,
  email,
}: {
  email?: string;
  plan: string;
}) => {
  const roktTransactionId = getRoktCookie();

  if (roktTransactionId) {
    logEvent({
      eventName: "ROKTPaymentCompleted",
      passbackconversiontrackingid: roktTransactionId,
      email,
      plan,
    });
  }
};
