import React from "react";
import styled from "styled-components";
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  SpaceProps,
  layout,
  space,
  color,
  typography,
  textShadow,
  background,
  border,
  TypographyProps,
  TextShadowProps,
  BackgroundProps,
  compose,
} from "styled-system";
import { css } from "@styled-system/css";
import { textVariants } from "../Text/textVariants";
import { Theme } from "../theme";

type ReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type BaseInputProps = SpaceProps<Theme> &
  TypographyProps<Theme> &
  ColorProps<Theme> &
  TextShadowProps<Theme> &
  LayoutProps<Theme> &
  BackgroundProps<Theme> &
  BorderProps<Theme> &
  ReactInputProps;

export const makeBaseInputStyle = css({
  "outline": "none",
  "bg": "backgroundSecondary",
  "borderStyle": "solid",
  "borderRadius": "full",
  "borderWidth": "sm",
  "borderColor": "borderLight",
  "py": "sm",
  "pl": "md",
  "color": "contentContrastHigh",
  "&:hover": {
    borderColor: "borderDark",
  },
  "&:focus": {
    borderColor: "borderDark",
  },
  "&:disabled": {
    borderColor: "borderDark",
  },
  "::placeholder": {
    color: "contentContrastMid",
  },
  ...textVariants.textInput,
});

export const makeErrorInputStyle = css({
  "borderColor": "contentNegative",
  "&:hover": {
    borderColor: "contentNegative",
  },
});

export const _Input = styled.input(
  compose(space, color, typography, textShadow, layout, background, border),
  makeBaseInputStyle,
) as React.FC<BaseInputProps>;
