import { useStoreActions } from "easy-peasy";
import { useCallback, useEffect, useState } from "react";

import { getRedirectURL } from "../helpers";
import { getEnvConfig } from "../../../utils/appConfig/envConfig";

export const useFacebookLogin = ({
  onError,
  onSuccess,
}: {
  onError: (_message?: string) => void;
  onSuccess: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const logInFacebook = useStoreActions(
    (actions) => (actions.auth as any).logInFacebook,
  );

  const env = getEnvConfig();

  const loadSdk = useCallback(() => {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    script.crossOrigin = "anonymous";

    const element = document.body.appendChild(script);
    element.id = facebookSdkId;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initialize = useCallback(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: env.kicweb_facebook_app_id,
        xfbml: false,
        version: "v18.0",
      });
    };
  }, []);

  useEffect(() => {
    if (document.getElementById(facebookSdkId)) {
      return;
    }
    loadSdk();
    initialize();
  }, []);

  const handleLoginResponse = useCallback(
    async (response: Response) => {
      setIsLoading(true);
      if (response.status === "connected") {
        const { accessToken } = response.authResponse;

        const loginResponse = await logInFacebook({
          access_token: accessToken,
          request_uri: getRedirectURL(),
        });

        setIsLoading(false);

        if (loginResponse.error) {
          onError(loginResponse.error.message);

          return;
        }
        onSuccess();
      } else {
        onError();
        setIsLoading(false);
      }
    },
    [logInFacebook],
  );

  const onLoginPress = useCallback(() => {
    window.FB.login(handleLoginResponse, {
      scope: "public_profile, email",
    });
  }, [handleLoginResponse]);

  return { onLoginPress, isLoading };
};

declare global {
  interface Window {
    FB: {
      init: (_params: InitParams) => void;
      getLoginStatus: (_responseCallback: ResponseCallback) => void;
      login: (
        _responseCallback: ResponseCallback,
        _scope: { scope: string },
      ) => void;
    };
    fbAsyncInit: VoidFunction;
  }
}

type ResponseCallback = (_response: Response) => void;

type Response = {
  status: "connected" | "not_authorized" | "unknown";
  authResponse: {
    accessToken: string;
    expiresIn: number;
    data_access_expiration_time: number;
    signedRequest: string;
    userID: string;
  };
};

type InitParams = {
  appId: string;
  xfbml: boolean;
  version: string;
};

const facebookSdkId = "facebook-jssdk";