import Head from "next/head";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import { useStoreActions } from "../../../store";
import { logEvent } from "../../utils/monitoring";
import isDynamicRoute from "../../utils/isDynamicRoute";
import { userLoginLandingPage } from "../../utils/constants";
import { useAppleLogin } from "../../features/Auth/hooks/useAppleLogin";
import { useFacebookLogin } from "../../features/Auth/hooks/useFacebookLogin";
import { useReferralParam } from "../../features/Checkout/hooks/useReferralParam";
import { LoginView } from "../../features/Auth/EmailLogin/LoginView";

const LogInScreen = () => {
  const router = useRouter();
  const logIn = useStoreActions((actions) => actions.auth.logIn);
  const logOut = useStoreActions((actions) => actions.auth.logOut);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { isLoading: isLoadingFacebook } = useFacebookLogin({
    onError: () => null,
    onSuccess: () => {
      onSuccessfulLogin();
    },
  });

  const { isLoading: isLoadingApple } = useAppleLogin({
    onError: () => null,
    onSuccess: () => {
      onSuccessfulLogin();
    },
  });

  const { next, campaignId } = useReferralParam();

  const onSuccessfulLogin = useCallback(() => {
    const query = { campaignId };
    if (next) {
      const { pathname, asPath } = isDynamicRoute(next, query);
      router.push({ pathname, query }, asPath, { shallow: true });
    } else {
      router.push(userLoginLandingPage);
    }
  }, [next, campaignId, router]);

  useEffect(() => {
    const { query } = router;
    if (query.next) {
      logOut();
    }
  }, []);

  const onLogIn = async () => {
    if (!email || !password) {
      setError("Please enter a valid email and password.");
      return;
    }

    setIsLoading(true);

    logEvent({ eventName: "AuthSignInStarted", method: "email" });
    const response = await logIn({
      email: email.toLowerCase().trim(),
      password,
    });

    setIsLoading(false);
    try {
      if (!response || response.error) {
        throw response?.error;
      } else {
        onSuccessfulLogin();
      }
    } catch (error) {
      setError(error?.message || "An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <Head>
        <title>Log In — Kic</title>
      </Head>
      <LoginView
        onLogInPress={onLogIn}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        isLoading={isLoading || isLoadingApple || isLoadingFacebook}
        error={error}
      />
    </>
  );
};

export default LogInScreen;
