const isDynamicRoute = (route, query = {}) => {
  const splitRoute = route.split("/");

  if (
    splitRoute.length >= 4 &&
    splitRoute[1] === "dashboard" &&
    splitRoute[2] === "profile"
  ) {
    const queryString = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join("&");

    return {
      pathname: `/dashboard/profile/[tab]${
        queryString ? `?${queryString}` : ""
      }`,
      asPath: route,
    };
  } else {
    return {
      pathname: route,
    };
  }
};

export default isDynamicRoute;
