import React, { useRef, useEffect } from "react";
import styled from "styled-components";

import { useIsMobile } from "../../../hooks";
import { Box, Image } from "../../../ui";

const IMAGES = [
  "https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/12/1701747873115.png",
  "https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/12/1701747893185.png",
  "https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/12/1701747907489.png",
  "https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/12/1701747941629.png",
  "https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/12/1701747916797.png",
];

const AnimationContainer = styled(Box)`
  .image-container {
    position: absolute;
  }

  .image-container:nth-of-type(1) {
    animation-name: fade;
    animation-delay: 5s;
    animation-duration: 1s;
    z-index: 20;
  }
  .image-container:nth-of-type(2) {
    z-index: 10;
  }
  .image-container:nth-of-type(n + 3) {
    display: none;
  }

  @keyframes fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const OnboardingImageCarousel: React.FC = () => {
  const { isMobile } = useIsMobile();
  const animationContainer = useRef<HTMLDivElement>();

  useEffect(() => {
    const images =
      animationContainer.current.getElementsByClassName("image-container");
    const fadeComplete = () =>
      animationContainer.current.appendChild(images[0]);
    Array.from(images).forEach((imgElement) =>
      imgElement.addEventListener("animationend", fadeComplete, false),
    );
  }, []);

  return (
    <AnimationContainer
      ref={animationContainer}
      position="relative"
      overflow="hidden"
      height={["35vh", "100%"]}
      style={{
        clipPath: isMobile
          ? "ellipse(150% 100% at 50% 0%)"
          : "ellipse(100% 150% at 0% 50%)",
      }}
    >
      {IMAGES.map((imageSrc) => (
        <Box
          key={imageSrc}
          className="image-container"
          height="100%"
          width="100%"
        >
          <Image
            src={imageSrc}
            height="100%"
            style={{ objectFit: "cover", objectPosition: "center" }}
          />
        </Box>
      ))}
    </AnimationContainer>
  );
};
